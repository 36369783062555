import { addDays } from 'date-fns';
import { configs } from '../../app/app-configs';
import { KeyValuePair } from '../../model/key-value-pair';
import { Pagination } from '../../model/pagination';

export interface Attendance {
    id: number;
    memberId: number;
    firstName: string;
    lastName: string;
    date: string;
    time?: string;
}

export interface QrAttendanceRequest {
    memberId: number;
    pin: number;
}

export interface AttendanceSummary {
    todayCount: number;
    currentWeekCount: number;
    currentMonthCount: number;
    lastMonthCount: number;
    dailyAttendanceTrend: KeyValuePair<number>[];
    averageAttendanceForDayOfWeek: KeyValuePair<number>[];
    mostActiveMembers: KeyValuePair<number>[];
    // countByPlan: KeyValuePair<number>[]
    // countByStatus: KeyValuePair<number>[]
}

export interface AttendanceSearchCriteria {
    pagination: Pagination;
    from: string;
    to: string;
    name: string;
}

export const getDefaultAttendanceSearchCriteria =
    (): AttendanceSearchCriteria => {
        return {
            pagination: { page: 0, size: configs.PAGE_SIZE },
            name: '',
            from: addDays(new Date(), -7).toISOString().slice(0, 10),
            to: new Date().toISOString().slice(0, 10),
        };
};