import React from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, Tooltip } from '@mui/material';

interface CopyToClipboardProps {
    value: string;
    tooltip: string;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({value, tooltip}) => {
    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(value);
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    };

    return (
            <Tooltip title={tooltip}>
                <Button onClick={handleCopy} sx={{minWidth: '30px'}}>
                    <ContentCopyIcon sx={{height: '20px', color: '#216C17'}}/>
                </Button>
            </Tooltip>
    );
}

export default CopyToClipboard;