import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
    fetchMyPayments,
    selectMyPayments,
} from '../features/billing/billing.slice';
import { selectLoggedInUser } from '../features/auth/auth.slice';

const useMyPayments = () => {
    const dispatch = useAppDispatch();
    const payments = useAppSelector(selectMyPayments);
    const loggedInUserDetails = useAppSelector(selectLoggedInUser);
    const loggedInUser = loggedInUserDetails?.user;

    const getMyPayments = async () => {
        if (loggedInUser) {
            await dispatch(fetchMyPayments(loggedInUser.businessId));
        }
    };

    useEffect(() => {
        if (payments.length === 0) {
            getMyPayments();
        }
    }, []);

    return [payments];
};

export default useMyPayments;
