import { UseFormRegisterReturn, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Dialog, DialogTitle, FormControlLabel, Grid, IconButton, Radio, RadioGroup } from "@mui/material";
import FormField from "../../../../components/form-controls/form-field";
import CloseIcon from '@mui/icons-material/Close';
import theme from "../../../../app/app-theme";
import { TextInput } from "../../../../components/form-input-field-wrappers/text-input";
import { DropdownInput } from "../../../../components/form-input-field-wrappers/dropdown-input";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { MemberMessageSearchCriteria, MemberMessageRequest } from "../../../../service/model/message.model";
import { addDays } from "date-fns";
import MemberMessageTable from "./member-message-table";
import * as messageSlice from "../../member-message.slice";
import { Member } from "../../../../service/model/member.model";
import { selectLoggedInUser } from "../../../auth/auth.slice";
import { getDefaultMessage } from "./message-template";
import { MessageChannel, MessageType } from "../../../../shared/enums";

interface MemberMessageModalProps {
    readonly member: Member
    readonly onClose: () => void
}

const mapMessageType = (type: string): string => {
    if (type === MessageType.PAYMENT_REMINDER) {
        return "payment_reminder";
    } else if (type === MessageType.DIET_PLAN) {
        return "diet_plan";
    } else if (type === MessageType.WORKOUT_SCHEDULE) {
        return "workout_schedule";
    } else {
        return "";
    }
}

const style = {
    // position: 'absolute' as 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    // width: 400,
    // bgcolor: 'background.paper',
    // border: '2px solid #000',
    // boxShadow: 24,
    p: 2,
};

const validationSchema = yup.object().shape({
    messageType: yup.string().required("Type is required"),
    messageContent: yup.string(),
});

export type FormValues = yup.InferType<typeof validationSchema>;

const MemberMessageModal: React.FC<MemberMessageModalProps> = ({ member, onClose }) => {
    const dispatch = useAppDispatch();
    const messages = useAppSelector(messageSlice.selectMessages);
    const loggedInUser = useAppSelector(selectLoggedInUser);
    const [selectedChannel, setSelectedChannel] = useState(MessageChannel.SMS);
    const { register, watch, setValue, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const messageType = watch("messageType");

    const onSubmitHandler = (data: any) => {
        const request = {
            memberId: member.id,
            messageType: mapMessageType(data.messageType),
            messageContent: data.messageContent,
            channel: selectedChannel
        } as MemberMessageRequest
        dispatch(messageSlice.sendMessageToMember(request))
        onClose();
    };

    const getRegister = (name: keyof FormValues, validation?: any): UseFormRegisterReturn => {
        return register(name, validation);
    };

    useEffect(() => {
        const searchCriteria = {
            memberId: member.id,
            pagination: {
                page: 0,
                size: 10
            },
            from: addDays(new Date(), -30).toISOString().slice(0, 10),
            to: new Date().toISOString().slice(0, 10),
        } as MemberMessageSearchCriteria;
        dispatch(messageSlice.fetchMemberMessagePage(searchCriteria));
    }, []);


    const onClear = () => {
        setValue("messageContent", "");
        setSelectedChannel(MessageChannel.SMS)
    }



    useEffect(() => {
        if (messageType) {
            const message = getDefaultMessage(messageType, member, loggedInUser?.business.name ?? "");

            setValue("messageContent", message);
            setSelectedChannel(MessageChannel.SMS)
        }
    }, [messageType, member, loggedInUser, setValue]);

    return (
        <Dialog open={true} onClose={onClose} sx={style} fullWidth maxWidth="md">
            <DialogTitle>Message Details</DialogTitle>

            <Box sx={style}>
                <>
                    <IconButton aria-label="close" onClick={onClose} sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}>
                        <CloseIcon />
                    </IconButton>
                    <form onSubmit={handleSubmit(onSubmitHandler)}>
                        <FormField>
                            <DropdownInput
                                id={"type"}
                                label="Message Type"
                                defaultValue={"Payment Reminder"}
                                error={errors.messageType !== undefined}
                                helperText={errors.messageType?.message}
                                options={Object.values(MessageType).map(type => { return { label: type, value: type } })}
                                register={register("messageType")}
                            />
                        </FormField>
                        {(messageType === MessageType.DIET_PLAN || messageType === MessageType.WORKOUT_SCHEDULE) &&
                            <FormField>
                                <TextInput
                                    id="content"
                                    label="Message Content"
                                    multiline={true}
                                    variant="outlined"
                                    // defaultValue={getDefaultMessage()}
                                    error={errors.messageContent !== undefined}
                                    helperText={errors.messageContent?.message}
                                    register={register("messageContent")}
                                />
                            </FormField>
                        }
                        <FormField>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={selectedChannel}
                                row
                            >
                                <FormControlLabel value="sms" onClick={() => setSelectedChannel(MessageChannel.SMS)} control={<Radio />} label="SMS" />
                                {messageType === MessageType.PAYMENT_REMINDER &&
                                    <FormControlLabel value="whatsapp" onClick={() => setSelectedChannel(MessageChannel.WHATSAPP)} control={<Radio />} label="WhatsApp" />
                                }
                            </RadioGroup>
                        </FormField>
                        <Grid container direction="row" justifyContent='end' sx={{ mt: 1 }}>
                            <Button type="button" variant="contained" sx={{ mr: 1, bgcolor: `${theme.palette.secondary.main}` }} onClick={onClear}>Clear</Button>
                            <Button type="submit" variant="contained" >Send</Button>
                        </Grid>
                    </form>
                </>
                <MemberMessageTable memberId={member.id} messages={messages} />
            </Box>
        </Dialog>
    );
}

export default MemberMessageModal;