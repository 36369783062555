import {
    PayloadAction,
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import * as businessPaymentService from '../../service/business-payment.service';
import {
    BusinessPayment
} from '../../service/model/business-payment.model';

export interface BillingState {
    isLoading: boolean;
    payments: BusinessPayment[];
    // summary?: BillingSummary;
}

const billingAdapter = createEntityAdapter({
    selectId: (payment: BusinessPayment) => payment.id,
    sortComparer: (a, b) => b.paymentDate.localeCompare(a.paymentDate),
});

export const initialState = billingAdapter.getInitialState({
    isLoading: false,
    payments: [],
} as BillingState);

// export const fetchBillingSummary = createAsyncThunk<BillingSummary>(
//     'billing/summary',
//     async (_, { rejectWithValue }) => {
//         return businessPaymentService
//             .getMyPaymentSummary()
//             .catch((errors) => {
//                 return rejectWithValue(errors);
//             });
//         return {} as BillingSummary;
//     }
// );

export const fetchMyPayments = createAsyncThunk<BusinessPayment[], number>(
    'billing/my-payments',
    async (businessId, { rejectWithValue }) => {
        return businessPaymentService
            .getMyPayments(businessId)
            .catch((errors) => {
                return rejectWithValue(errors);
            });
    }
);

const billingSlice = createSlice({
    name: 'billing',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // .addCase(fetchBillingSummary.pending, (state) => {
            //     state.isLoading = true;
            // })
            // .addCase(
            //     fetchBillingSummary.fulfilled,
            //     (state, action: PayloadAction<BillingSummary>) => {
            //         state.summary = action.payload;
            //         state.isLoading = false;
            //     }
            // )
            // .addCase(fetchBillingSummary.rejected, (state) => {
            //     state.isLoading = false;
            // })
            .addCase(fetchMyPayments.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(
                fetchMyPayments.fulfilled,
                (state, action: PayloadAction<BusinessPayment[]>) => {
                    billingAdapter.setAll(state, action.payload);
                    state.isLoading = false;
                }
            )
            .addCase(fetchMyPayments.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

export const { selectAll: selectMyPayments, selectById: selectMyPaymentById } =
    billingAdapter.getSelectors<RootState>((state) => state.billing);

export const {} = billingSlice.actions;

export const selectIsLoading = (state: RootState) => state.billing.isLoading;
// export const selectMyPaymentSummary = (state: RootState) =>
//     state.billing.summary;

export default billingSlice.reducer;
