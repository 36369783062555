import { useState } from "react";
import * as attendanceSlice from '../features/attendance/attendance.slice';
import { useAppDispatch } from "../app/hooks";
import { Avatar, Box, Button, Container, TextField, Typography } from "@mui/material";
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import Copyright from "../components/copyright";
import { QrAttendanceRequest } from "../service/model/attendance.model";

const AttendanceQRForm = () => {
    const dispatch = useAppDispatch();

    const [memberId, setMemberId] = useState('');
    const [pin, setPin] = useState('');


    const markAttendance = async () => {
        const sendLoginRequest = async () => {
            await dispatch(attendanceSlice.createAttendanceQR({ memberId: Number(memberId), pin: Number(pin) } as QrAttendanceRequest))
        }

        await sendLoginRequest();
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <FitnessCenterIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Mark your attendance
                </Typography>
                <Box sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="memberId"
                        label="Member ID"
                        name="memberId"
                        type="number"
                        autoComplete="memberId"
                        autoFocus
                        onChange={e => setMemberId(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="pin"
                        label="PIN"
                        type="number"
                        id="pin"
                        autoComplete="pin"
                        onChange={e => setPin(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={markAttendance}
                    >
                        Submit Attendance
                    </Button>
                </Box>
            </Box>
            <Box sx={{ mt: 8, mb: 4 }}>
                <Copyright/>
            </Box>
        </Container>
    );
}

export default AttendanceQRForm;