import { Member } from '../../../../service/model/member.model';
import { MessageType } from '../../../../shared/enums';

// const getPaymentReminderMessage = (member: Member, business: string) => {
//     return `Friendly Reminder: Payment Due

// Hi ${member.firstName},

// We hope you're doing well! We wanted to remind you that your membership has expired, and we truly miss having you with us.

// Expired On: ${member.expiryDate}

// To continue enjoying our facilities and services, please consider renewing your membership. We have exciting new classes and programs waiting for you!

// If you have already made the payment, please disregard this message. Feel free to reach out if you have any questions or need assistance.

// We'd love to see you back!

// Stay healthy,
// ${business}`;
// }

const getDietPlanMessage = (member: Member, business: string) => {
    return `Your Personalized Diet Plan is Ready!

Hi ${member.firstName},

We hope you're doing great!
As part of your fitness journey with us, we've prepared a personalized diet plan tailored to your goals.

Diet Plan Overview:
- Breakfast:
- Lunch:
- Dinner: 
- Snacks: 
- Special Notes: 

Please let us know if you have any questions or need adjustments.

Stay fit,
${business}`;
};

const getWorkoutScheduleMessage = (member: Member, business: string) => {
    const workoutSchedule = `
Workout Schedule:
- Monday: Cardio & Core - 45 mins
- Tuesday: Upper Body Strength - 60 mins
- Wednesday: Rest or Yoga - 30 mins
- Thursday: Lower Body Strength - 60 mins
- Friday: Full-Body Circuit - 45 mins
- Saturday: Outdoor Activity - 30 mins
- Sunday: Rest or Light Stretching
`;

    return `Your Workout Schedule is Ready!

Hi ${member.firstName},

We’re excited to share your personalized workout schedule! This plan is designed to help you achieve your fitness goals efficiently and effectively.
${workoutSchedule}
If you have any questions or need adjustments to your schedule, feel free to reach out, we are here to support you!

Stay strong,  
${business}`;
};

export const getDefaultMessage = (
    selectedMessageType: string,
    member: Member,
    business: string
) => {
    if (selectedMessageType === MessageType.PAYMENT_REMINDER) {
        return '';
    } else if (selectedMessageType === MessageType.DIET_PLAN) {
        return getDietPlanMessage(member, business);
    } else if (selectedMessageType === MessageType.WORKOUT_SCHEDULE) {
        return getWorkoutScheduleMessage(member, business);
    } else {
        return '';
    }
};
