import { Route, createRoutesFromElements } from "react-router";
import { createBrowserRouter } from "react-router-dom";
import PrivateRoute from "../helpers/PrivateRoute";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import Secured from "../pages/Securedpage";
import HomePage from "../pages/Homepage";
import RootLayout from "../components/layout/RootLayout";
import PageNotFound from "../pages/error/PageNotFound";
import Plans from "../pages/Plans";
import Business from "../pages/Business";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import ChangePassword from "../pages/ChangePassword";
import Memberships from "../pages/memberships";
import Members from "../pages/member";
import Attendance from "../pages/attendance";
import BusinessPayments from "../pages/business-payments";
import MemberPayments from "../pages/member-payments";
import Messages from "../pages/messages";
import Users from "../pages/users";
import Pricing from "../pages/Pricing";
import Features from "../pages/Features";
import ApplicationError from "../pages/error/ApplicationError";
import Billing from "../pages/billing";
import AttendanceQRForm from "../pages/attendance-qr";


export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route path="/" element={<HomePage />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/features" element={<Features />} />
      <Route path="/login" element={<Login />} />
      <Route path="/mark-attendance-qr" element={<AttendanceQRForm />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route
        path="/secured"
        element={
          <PrivateRoute>
            <Secured />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/plans"
        element={
          <PrivateRoute>
            <Plans />
          </PrivateRoute>
        }
      />
      <Route
        path="/business"
        element={
          <PrivateRoute>
            <Business />
          </PrivateRoute>
        }
      />
      <Route
        path="/memberships"
        element={
          <PrivateRoute>
            <Memberships />
          </PrivateRoute>
        }
      />
      <Route
        path="/members"
        element={
          <PrivateRoute>
            <Members />
          </PrivateRoute>
        }
      />
      <Route
        path="/attendance"
        element={
          <PrivateRoute>
            <Attendance />
          </PrivateRoute>
        }
      />
      <Route
        path="/messages"
        element={
          <PrivateRoute>
            <Messages />
          </PrivateRoute>
        }
      />
      <Route
        path="/users"
        element={
          <PrivateRoute>
            <Users />
          </PrivateRoute>
        }
      />
      <Route
        path="/business-payments"
        element={
          <PrivateRoute>
            <BusinessPayments />
          </PrivateRoute>
        }
      />
      <Route
        path="/member-payments"
        element={
          <PrivateRoute>
            <MemberPayments />
          </PrivateRoute>
        }
      />
      <Route
        path="/billing"
        element={
          <PrivateRoute>
            <Billing />
          </PrivateRoute>
        }
      />
      <Route
        path="/change-password"
        element={
          <PrivateRoute>
            <ChangePassword />
          </PrivateRoute>
        }
      />
      <Route
        path="/application-error"
        element={
          <PrivateRoute>
            <ApplicationError />
          </PrivateRoute>
        }
      />
      <Route path="*" element={<PageNotFound />} />
    </Route>
  )
)