import {
    PayloadAction,
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import * as messageService from '../../service/message.service';

import { showNotification } from '../alerts/alert.slice';
import { NotificationType } from '../../shared/enums';
import { getAxiosErrorMessage } from '../../service/axios/axios.call.service';
import { PaginatedResponse } from '../../service/model/paginated-response';
import {
    getDefaultBusinessMessageSearchCriteria,
    BusinessMessage,
    BusinessMessageRequest,
    BusinessMessageSearchCriteria,
    BusinessMessageSummary,
} from '../../service/model/message.model';
import { StatusResponseWithMessage } from '../../service/model/auth.model';

export interface MessageState {
    isLoading: boolean;
    summary?: BusinessMessageSummary;
    page?: PaginatedResponse<BusinessMessage>;
    totalPages: number;
    currentPage: number;
    totalRecords: number;
}

const businessMessageAdapter = createEntityAdapter({
    selectId: (message: BusinessMessage) => message.id,
    sortComparer: (a, b) => b.createdOn?.localeCompare(a.createdOn),
});

export const initialState = businessMessageAdapter.getInitialState({
    isLoading: false,
    totalPages: 0,
    currentPage: 0,
    totalRecords: 0,
} as MessageState);

export const fetchBusinessMessageSummary =
    createAsyncThunk<BusinessMessageSummary>(
        'businessMessage/summary',
        async (_, { rejectWithValue }) => {
            return messageService
                .getBusinessMessageSummary()
                .catch((errors) => {
                    return rejectWithValue(errors);
                });
        }
    );

export const fetchBusinessMessagePage = createAsyncThunk<
    PaginatedResponse<BusinessMessage>,
    BusinessMessageSearchCriteria
>('businessMessage/page', async (searchCriteria, { rejectWithValue }) => {
    return messageService
        .getBusinessMessagePage(searchCriteria)
        .catch((errors) => {
            return rejectWithValue(errors);
        });
});

export const sendMessageToBusiness = createAsyncThunk<
    StatusResponseWithMessage,
    BusinessMessageRequest
>('businessMessage/create', async (request, { rejectWithValue, dispatch }) => {
    return messageService
        .sendMessageToBusiness(request)
        .then((response) => {
            dispatch(
                showNotification({
                    message: response.message,
                    type:
                        response.status === 'success'
                            ? NotificationType.Success
                            : NotificationType.Error,
                })
            );
            setTimeout(() => {
                dispatch(
                    fetchBusinessMessagePage({
                        ...getDefaultBusinessMessageSearchCriteria(),
                        businessId: request.businessId,
                    })
                );
            }, 3000);
            // dispatch(fetchBusinesss());
            return response;
        })
        .catch((errors) => {
            dispatch(
                showNotification({
                    message: getAxiosErrorMessage(errors.response),
                    type: NotificationType.Error,
                })
            );
            return rejectWithValue(errors);
        });
});

const businessMessageSlice = createSlice({
    name: 'businessMessage',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchBusinessMessageSummary.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(
                fetchBusinessMessageSummary.fulfilled,
                (state, action: PayloadAction<BusinessMessageSummary>) => {
                    state.summary = action.payload;
                    state.isLoading = false;
                }
            )
            .addCase(fetchBusinessMessageSummary.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(fetchBusinessMessagePage.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(
                fetchBusinessMessagePage.fulfilled,
                (
                    state,
                    action: PayloadAction<PaginatedResponse<BusinessMessage>>
                ) => {
                    businessMessageAdapter.setAll(state, action.payload.data);
                    state.currentPage = action.payload.currentPage;
                    state.totalPages = action.payload.totalPages;
                    state.totalRecords = action.payload.totalRecords;
                    state.isLoading = false;
                }
            )
            .addCase(fetchBusinessMessagePage.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(sendMessageToBusiness.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(
                sendMessageToBusiness.fulfilled,
                (state, action: PayloadAction<StatusResponseWithMessage>) => {
                    // messageAdapter.addOne(state, action.payload);
                    state.isLoading = false;
                }
            )
            .addCase(sendMessageToBusiness.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

export const { selectAll: selectMessages, selectById: selectMessageById } =
    businessMessageAdapter.getSelectors<RootState>(
        (state) => state.businessMessages
    );

export const {} = businessMessageSlice.actions;

export const selectIsLoading = (state: RootState) =>
    state.businessMessages.isLoading;
export const selectBusinessMessageSummary = (state: RootState) =>
    state.businessMessages.summary;
export const selectCurrentPage = (state: RootState) =>
    state.businessMessages.currentPage;
export const selectTotalPages = (state: RootState) =>
    state.businessMessages.totalPages;
export const selectTotalRecords = (state: RootState) =>
    state.businessMessages.totalRecords;

export default businessMessageSlice.reducer;
