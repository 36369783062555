import React from "react";
import { BusinessPayment } from "../../../service/model/business-payment.model";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
    createStyles({
        card: {
            '& .MuiCardContent-root': {
                paddingBottom: '12px !important',
            }
        }
    }
    )
)
interface PaymentHistoryProps {
    payments: BusinessPayment[];
}

const PaymentHistory: React.FC<PaymentHistoryProps> = ({ payments }) => {
    const classes = useStyles();

    return (
        <div style={{ marginTop: '12px' }}>
            <Typography variant="h6">Payment History</Typography>
            {payments.map(payment => {
                return <Card key={payment.id} sx={{ marginTop: '12px' }} className={classes.card}>
                    <CardContent sx={{ paddingBottom: "12px" }}>
                        <Grid container direction="row" justifyContent="space-around">
                            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                                {payment.paymentDate}
                            </Typography><Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                                {`Rs. ${payment.amount}`}
                            </Typography>
                            <CheckCircleOutlineIcon color="success" />
                        </Grid>
                    </CardContent>
                </Card>
            })

            }
        </div>
    );
}

export default PaymentHistory;