import { PayloadAction, createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import * as roleService from '../../service/role.service'
import { Role } from "../../service/model/role.model";

export interface RoleState {
    isLoading: boolean;
}

const rolesAdapter = createEntityAdapter({
    selectId: (role: Role) => role.id,
    sortComparer: (a, b) => a.name?.localeCompare(b.name),
  })
  
export const initialState = rolesAdapter.getInitialState({
    isLoading: false,
} as RoleState);

export const fetchRoles = createAsyncThunk<Role[]>(
    'roles/all',
    async (_, {rejectWithValue}) => {
        return roleService.getRoles().catch((errors) => {
            return rejectWithValue(errors);
        })
    }
);

const roleSlice = createSlice({
    name: 'roles',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchRoles.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(fetchRoles.fulfilled, (state, action: PayloadAction<Role[]>) => {
            rolesAdapter.setAll(state, action.payload);
            state.isLoading = false;
        })
        .addCase(fetchRoles.rejected, (state) => {
            state.isLoading = false;
        });
    }
});

export const {
    selectAll: selectRoles,
    selectById: selectRoleById
} = rolesAdapter.getSelectors<RootState>((state) => state.roles);

export const {} = roleSlice.actions;

export const selectIsLoading = (state: RootState) => state.roles.isLoading;

export default roleSlice.reducer;