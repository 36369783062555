import useMyPayments from "../hooks/use-my-payments";
import { useAppSelector } from "../app/hooks";
import * as mypaymentSlice from "../features/business-payments/business-payment.slice";
import Loader from "../components/loader";
import PaymentHistory from "../features/billing/components/payment-history";
import BillingInformation from "../features/billing/components/billing-information";

const Billing = () => {
    const isLoading = useAppSelector(mypaymentSlice.selectIsLoading);
    const [myPayments] = useMyPayments();

    return (
        <div>
            {isLoading &&
                <Loader />
            }
            <BillingInformation />
            {myPayments &&
                <PaymentHistory payments={myPayments ?? []} />
            }
        </div>
    )
}

export default Billing;