import { UseFormRegisterReturn, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Business } from "../../../service/model/business.model";
import { Box, Button, Dialog, DialogTitle, Grid, IconButton } from "@mui/material";
import FormField from "../../../components/form-controls/form-field";
import CloseIcon from '@mui/icons-material/Close';
import theme from "../../../app/app-theme";
import usePlans from "../../../hooks/use-plans";
import { TextInput } from "../../../components/form-input-field-wrappers/text-input";
import { DropdownInput } from "../../../components/form-input-field-wrappers/dropdown-input";

interface BusinessFormProps {
    readonly business?: Business
    readonly editable: boolean
    readonly onClose: () => void
    readonly onSubmit: (data: Business) => void
}

const style = {
    // position: 'absolute' as 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    // width: '100%',
    // bgcolor: 'background.paper',
    // border: '2px solid #000',
    // boxShadow: 24,
    p: 2,
};

const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    address: yup.string(),
    email: yup.string().email("Not a valid email"),
    contactPerson: yup.string(),
    contactNumber: yup.string(),
    planId: yup.number().transform((value) => Number.isNaN(value) ? null : value ).required("Plan is required"),
    discountPercentage: yup.number().min(0).max(100),
    dueAmount: yup.number(),
    expiryDate: yup.string(),
    createdDate: yup.string(),
    status: yup.string().required("Status is required")
});

export type FormValues = yup.InferType<typeof validationSchema>;

const BusinessForm: React.FC<BusinessFormProps> = ({ business, editable, onClose, onSubmit }) => {
    const [plans] = usePlans();

    const { register, handleSubmit, formState: { errors } } = useForm<FormValues>({
        resolver: yupResolver(validationSchema)
    });

    const onSubmitHandler = (data: any) => {
        const updatedBusiness = {
            ...business,
            ...data
        }
        onSubmit(updatedBusiness as Business)
    };

    const getRegister = (name: keyof FormValues, validation?: any): UseFormRegisterReturn => {
        return register(name, validation);
    };

    return (
        <Dialog open={true} onClose={onClose} sx={style} fullWidth maxWidth='md'>
            <DialogTitle sx={{ paddingBottom: 0 }}>Business Details</DialogTitle>

            <Box sx={style}>
                <>
                    <IconButton aria-label="close" onClick={onClose} sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}>
                        <CloseIcon />
                    </IconButton>
                    <form onSubmit={handleSubmit(onSubmitHandler)}>
                        <Grid container direction="row" className="mt-0 my-0" justifyContent='space-between'>
                            <FormField>
                                {/* <TextField
                                    id="name"
                                    label="Name"
                                    variant="outlined"
                                    defaultValue={business?.name}
                                    error={errors.name !== undefined}
                                    helperText={errors.name?.message}
                                    {...register("name")}
                                /> */}
                                <TextInput
                                    id="name"
                                    label="Name"
                                    defaultValue={business?.name}
                                    error={errors.name !== undefined}
                                    helperText={errors.name?.message}
                                    register={getRegister('name')}
                                />
                            </FormField>
                            <FormField>
                                <DropdownInput
                                    id={"plan"}
                                    label="Plan"
                                    name="plan"
                                    defaultValue={business?.plan.id}
                                    error={errors.planId !== undefined}
                                    helperText={errors.planId?.message}
                                    options={plans.map(plan => {return {label: plan.name, value: plan.id}})}
                                    register={register("planId")}
                                />
                            </FormField>
                            <FormField>
                                <TextInput
                                    id="address"
                                    label="Address"
                                    defaultValue={business?.address}
                                    register={register("address")}
                                />
                            </FormField>
                            <FormField>
                                <TextInput
                                    id="contact"
                                    label="Contact Number"
                                    defaultValue={business?.contactNumber}
                                    register={register("contactNumber")}
                                />
                            </FormField>
                            <FormField>
                                <TextInput
                                    id="person"
                                    label="Contact Person"
                                    defaultValue={business?.contactPerson}
                                    register={register("contactPerson")}
                                />
                            </FormField>
                            <FormField>
                                <TextInput
                                    id="email"
                                    label="Email"
                                    defaultValue={business?.email}
                                    error={errors.email !== undefined}
                                    helperText={errors.email?.message}
                                    register={register("email")}
                                />
                            </FormField>
                            <FormField>
                                <TextInput
                                    id="discountPercentage"
                                    label="Discount Percentage"
                                    type="number"
                                    defaultValue={business?.discountPercentage ?? 0}
                                    error={errors.discountPercentage !== undefined}
                                    helperText={errors.discountPercentage?.message}
                                    register={register("discountPercentage")}
                                />
                            </FormField>
                            <FormField>
                                <TextInput
                                    id="dueAmount"
                                    label="Due Amount"
                                    type="number"
                                    defaultValue={business?.dueAmount ?? 0}
                                    error={errors.dueAmount !== undefined}
                                    helperText={errors.dueAmount?.message}
                                    register={register("dueAmount")}
                                />
                            </FormField>
                            <FormField>
                                <TextInput
                                    id="expiryDate"
                                    label="Expiry Date"
                                    type="date"
                                    // InputLabelProps={{
                                    //     shrink: true,
                                    // }}
                                    defaultValue={business?.expiryDate ?? ''}
                                    error={errors.expiryDate !== undefined}
                                    helperText={errors.expiryDate?.message}
                                    register={register("expiryDate")}
                                />
                            </FormField>
                            <FormField>
                                <TextInput
                                    id="createdDate"
                                    label="Joined Date"
                                    type="date"
                                    // InputLabelProps={{
                                    //     shrink: true,
                                    // }}
                                    defaultValue={business?.createdDate ?? new Date().toISOString().slice(0, 10)}
                                    error={errors.createdDate !== undefined}
                                    helperText={errors.createdDate?.message}
                                    register={register("createdDate")}
                                />
                            </FormField>
                            {/* <FormField>
                                <InputLabel >Status</InputLabel>
                                <Select
                                    id={"status"}
                                    label="Status"
                                    defaultValue={business?.status}
                                    error={errors.status !== undefined}
                                    {...register("status")}
                                >
                                    <MenuItem key='trial' value='Trial'>Trial</MenuItem>
                                    <MenuItem key='active' value='Active'>Active</MenuItem>
                                    <MenuItem key='expired' value='Expired'>Expired</MenuItem>
                                    <MenuItem key='deleted' value='Deleted'>Deleted</MenuItem>
                                    <MenuItem key='suspended' value='Suspended'>Suspended</MenuItem>
                                </Select>
                                <FormHelperText error={true}>{errors.status?.message}</FormHelperText>
                            </FormField> */}
                            <FormField>
                                <DropdownInput
                                    id={"status"}
                                    label="Status"
                                    defaultValue={business?.status}
                                    error={errors.status !== undefined}
                                    options={["Trial", "Active", "Expired", "Deleted", "Suspended"].map(item => {return {label: item, value: item}})}
                                    register={register("status")}
                                />
                            </FormField>
                        </Grid>
                        <Grid container direction="row" justifyContent='end' sx={{ mt: 1 }}>
                            <Button type="button" variant="contained" sx={{ mr: 1, bgcolor: `${theme.palette.secondary.main}` }} onClick={onClose}>Cancel</Button>
                            <Button type="submit" variant="contained">Submit</Button>
                        </Grid>
                    </form>
                </>
            </Box>
        </Dialog>
    );
}

export default BusinessForm;