import { useAppSelector } from "../app/hooks";
import { selectLoggedInUser } from "../features/auth/auth.slice";
import BusinessMessages from "../features/message/components/business/business-messages";
import MemberMessages from "../features/message/components/member/member-messages";
import { UserRoles } from "../shared/enums";

const Messages = () => {
    const loggedInUser = useAppSelector(selectLoggedInUser);
    const permissions = loggedInUser?.user.roles.map(role => role.name) as UserRoles[] ?? [];

    return (
        <div>
            {permissions.includes(UserRoles.Admin) &&
                <BusinessMessages />
            }
            {permissions.includes(UserRoles.Owner) &&
                <MemberMessages />
            }
        </div>
    )
}

export default Messages;