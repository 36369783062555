import { Box, Card, CardContent } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useAppSelector } from "../../../app/hooks";
import { selectLoggedInUser } from "../../auth/auth.slice";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      '& .MuiCardContent-root': {
        paddingBottom: '12px !important',
      }
    }
  }
  )
)

interface BillingInfoItemProps {
  label: string;
  value: string | number;
}

const BillingInfoItem: React.FC<BillingInfoItemProps> = ({ label, value }) => {
  return (
    <Box display="flex" justifyContent="space-between">
      <Typography variant="subtitle1" color="textSecondary">
        {label}
      </Typography>
      <Typography variant="subtitle1" color="textPrimary">
        {value}
      </Typography>
    </Box>
  );
};

const BillingInformation = () => {
  const classes = useStyles();
  const loggedInUserDetails = useAppSelector(selectLoggedInUser);

  return (
    <>
      <Typography variant="h6">Billing Information</Typography>
      <Box sx={{ maxWidth: { xs: '100%', sm: 275 }, width: '100%' }}>
        <Card variant="outlined" sx={{ marginTop: '12px' }} className={classes.card}>
          <CardContent sx={{ paddingBottom: "12px" }}>
            <BillingInfoItem label="Current Plan:" value={loggedInUserDetails?.plan.name ?? ''} />
            <BillingInfoItem label="Amount:" value={loggedInUserDetails?.plan.price ?? 0} />
            <BillingInfoItem label="Discount Percentage:" value={`${loggedInUserDetails?.business.discountPercentage ?? 0}%`} />
            <BillingInfoItem label="Expiry Date:" value={loggedInUserDetails?.business.expiryDate ?? 'N/A'} />
            <BillingInfoItem label="Due Amount" value={loggedInUserDetails?.business.dueAmount ?? '0'} />
            <BillingInfoItem label="Membership Status:" value={loggedInUserDetails?.business.status ?? ''} />
          </CardContent>
        </Card>
      </Box>
    </>
  );
}

export default BillingInformation;