import { Box, Button, Grid, Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import heroImage from "../resources/images/home/hero-image.png";
import theme from "../app/app-theme";
import { useState } from "react";
import RegistrationForm from "./table/registration-form";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        hero: {
            display: 'flex',
            minHeight: `100vh`,
            // backgroundImage: `url(${aboutBackground})`,
            backgroundRepeat: "no-repeat",
            background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(255,255,255,1) 0%, rgba(218,213,247,1) 37%, rgba(14,165,189,1) 100%)',
            // background: 'white',
            // backgroundPosition: "center",
            [theme.breakpoints.down('sm')]: {
                backgroundPosition: "right",
            }
        },
        textHeading: {
            color: `black`,
            fontSize: '64px',
            fontWeight: 'bold',
            textAlign: 'center'
        },
        textDesc: {
            color: `teal`,
            fontSize: '28px',
            lineHeight: '1.5',
            textAlign: 'center'
        },
    }
    ));

const Hero = () => {
    const classes = useStyles();
    const [showRegistrationForm, setShowRegistrationForm] = useState(false);

    return (
        //     <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.hero}>
        //   <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
        //     <div className="text-center">
        //       <h1 className={classes.textHeading}>Minimize Effort, Maximize Efficiency</h1>
        //       <h4 className={classes.textDesc}>Fitness center management software for increased organization and efficiency</h4>
        //     </div>
        //     <div className="text-center" style={{ marginTop: '2rem' }}>
        //     </div>
        //   </Grid>
        // </Grid>
        <Box sx={{ minHeight: '100vh', p: 4, display: 'flex', flexDirection: 'column', rowGap: 4 }} className={classes.hero}>
            <Grid container spacing={{ xs: 1, md: 2 }}
                sx={{ flexGrow: 1 }}>
                <>
                    <Grid sx={{ flexGrow: 1, position: 'relative', top: -60 }} item container md={6} xs={12} justifyContent="center" alignItems="center">
                        <div className="text-center">
                            <h1 className={classes.textHeading}>Minimize Effort, Maximize Efficiency</h1>
                            <h4 className={classes.textDesc}>Fitness center management software for increased organization and efficiency designed for gym owners</h4>
                        </div>
                        <div>
                            <Button
                                variant="contained"
                                size="large"
                                sx={{ backgroundColor: `${theme.palette.error.dark}`, height: "60px", width: "200px", fontSize: "20px" }}
                                onClick={() => { setShowRegistrationForm(true) }}
                            >
                                Register Now
                            </Button>
                        </div>

                    </Grid>
                    <Grid sx={{ flexGrow: 1, top: -30 }} item container md={6} xs={12} justifyContent="start" alignItems="start">
                        <img src={heroImage} style={{ maxWidth: "100%" }} alt="" />
                    </Grid>
                </>
            </Grid>
            {showRegistrationForm &&
                <RegistrationForm onClose={() => setShowRegistrationForm(false)}/>
            }
        </Box>
    );
}

export default Hero;