import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
    fetchMemberMessagePage,
    selectMessages,
} from '../features/message/member-message.slice';
import { getDefaultMemberMessageSearchCriteria } from '../service/model/message.model';

const useMemberMessages = () => {
    const dispatch = useAppDispatch();
    const messages = useAppSelector(selectMessages);

    const getMemberMessagePage = async () => {
        await dispatch(
            fetchMemberMessagePage(getDefaultMemberMessageSearchCriteria())
        );
    };

    useEffect(() => {
        if (messages.length === 0) {
            getMemberMessagePage();
        }
    }, []);

    return [messages];
};

export default useMemberMessages;
