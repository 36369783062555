import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { selectRoles, fetchRoles } from "../features/roles/role.slice";

const useRoles = () => {
    const dispatch = useAppDispatch();
    const roles = useAppSelector(selectRoles);

    const getRoles = async () => {
        await dispatch(fetchRoles());
    }

    useEffect(() => {
        if (roles.length === 0) {
            getRoles();
        }
    }, []);

    return [roles];
}
 
export default useRoles;