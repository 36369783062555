import { configs } from '../../app/app-configs';
import { Pagination } from '../../model/pagination';
import { Business } from './business.model';
import { Member } from './member.model';

export interface MemberMessage {
    id: number;
    member: Member;
    businessId: number;
    messageSid: string;
    messageContent: string;
    mediaUrl: string;
    status: string;
    conversationId: number;
    messageType: string;
    channel: string;
    errorCode: number;
    createdOn: string;
    updatedOn: string;
}

export interface BusinessMessage {
    id: number;
    business: Business;
    messageSid: string;
    messageContent: string;
    mediaUrl: string;
    status: string;
    conversationId: number;
    messageType: string;
    channel: string;
    errorCode: number;
    createdOn: string;
    updatedOn: string;
}

export interface MemberMessageSummary {
    todayTotal: number;
    currentYearTotal: number;
    currentMonthTotal: number;
    lastMonthTotal: number;
}

export interface BusinessMessageSummary {
    todayTotal: number;
    currentYearTotal: number;
    currentMonthTotal: number;
    lastMonthTotal: number;
}

export interface MemberMessageRequest {
    memberId: number;
    businessId: number;
    messageContent: string;
    channel: string;
    conversationId: number;
    messageType: string;
}

export interface BusinessMessageRequest {
    businessId: number;
    messageContent: string;
    channel: string;
    conversationId: number;
    messageType: string;
}

export interface MemberMessageSearchCriteria {
    pagination: Pagination;
    from: string;
    to: string;
    memberId?: number;
}

export interface BusinessMessageSearchCriteria {
    pagination: Pagination;
    from: string;
    to: string;
    businessId?: number;
}

export const getDefaultMemberMessageSearchCriteria =
    (): MemberMessageSearchCriteria => {
        const startOfMonth = new Date();
        startOfMonth.setUTCDate(1);
        return {
            pagination: { page: 0, size: configs.PAGE_SIZE },
            from: startOfMonth.toISOString().slice(0, 10),
            to: new Date().toISOString().slice(0, 10),
        };
    };

export const getDefaultBusinessMessageSearchCriteria =
    (): BusinessMessageSearchCriteria => {
        const startOfMonth = new Date();
        startOfMonth.setUTCDate(1);
        return {
            pagination: { page: 0, size: configs.PAGE_SIZE },
            from: startOfMonth.toISOString().slice(0, 10),
            to: new Date().toISOString().slice(0, 10),
        };
    };
