import { Box, Button, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import useMembers from "../hooks/use-members";
import { makeStyles } from "@mui/styles";
import MemberForm from "../features/members/components/member-form";
import { useEffect, useState } from "react";
import { Member as MemberModel } from "../service/model/member.model";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import * as memberSlice from "../features/members/member.slice";
import Loader from "../components/loader";
import MemberSummary from "../features/members/components/member-summary";
import MemberTable from "../features/members/components/member-table";
import SearchBox from "../components/search-box";
import { selectLoggedInUser } from "../features/auth/auth.slice";
import FormField from "../components/form-controls/form-field";

const useStyles = makeStyles({
    createButton: {
        float: 'inline-end',
        margin: '12px 0 4px 12px'
    }
})

const Members = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const loggedInUserDetails = useAppSelector(selectLoggedInUser);
    const loggedInUser = loggedInUserDetails?.user;

    const [showMemberForm, setShowMemberForm] = useState(false);
    const isLoading = useAppSelector(memberSlice.selectIsLoading);

    const [searchText, setSearchText] = useState('');
    const [membershipStatus, setMembershipStatus] = useState('');
    const [attendanceStatus, setAttendanceStatus] = useState('');


    const [members] = useMembers();

    const [filteredMembers, setFilteredMembers] = useState(members);

    useEffect(() => {
        if (searchText === '' && membershipStatus === '' && attendanceStatus === '') {
            setFilteredMembers(members);
        } else {
            setFilteredMembers(members.filter(item => (
                item.firstName.toLowerCase().includes(searchText.toLowerCase()) && 
                (membershipStatus === "" || item.membershipStatus === membershipStatus) && 
                (attendanceStatus === "" || item.attendanceStatus === attendanceStatus)
            )));
        }
    }, [searchText, membershipStatus, attendanceStatus, members]);

    const handleSubmit = (data: MemberModel) => {
        loggedInUser && dispatch(memberSlice.createMember({...data, businessId: loggedInUser.businessId})).then((resp) => {
            if (resp.meta.requestStatus === 'fulfilled') {
                setShowMemberForm(false);
            }
        });
    };

    function setName(name: string) {
        setSearchText(name);
    }

    return (
        <div>
            {isLoading &&
                <Loader />
            }
            {members &&
                <>
                    <MemberSummary />
                    <Box sx={{mt: '12px'}}>
                        <Button variant="contained" className={classes.createButton} onClick={() => setShowMemberForm(true)}>Add Member</Button>
                    </Box>
                    <Grid container direction="row" style={{ marginTop: '12px' }}>
                        <Grid item lg={4} md={12} sm={12} sx={{ width: "100%" }}>
                            <SearchBox placeholder="Search by name" setSearchValue={setName} />
                        </Grid>
                        <Grid container lg={4} md={12} sm={12} sx={{ width: "100%" }}>
                            <FormField>
                                <InputLabel >Attendance</InputLabel>
                                <Select
                                    id={"attendanceStatus"}
                                    label="Attendance Status"
                                    style={{ height: "50px" }}
                                    defaultValue={attendanceStatus}
                                    onChange={(e) => setAttendanceStatus(e.target.value)}
                                >
                                    <MenuItem key='none' value=''>None</MenuItem>
                                    <MenuItem key='active' value='Active'>Active</MenuItem>
                                    <MenuItem key='inactive' value='Inactive'>Inactive</MenuItem>
                                </Select>
                            </FormField>
                            <FormField>
                                <InputLabel >Membership</InputLabel>
                                <Select
                                    id={"membershipStatus"}
                                    label="Membership Status"
                                    style={{ height: "50px" }}
                                    defaultValue={membershipStatus}
                                    onChange={(e) => setMembershipStatus(e.target.value)}
                                >
                                    <MenuItem key='none' value=''>None</MenuItem>
                                    <MenuItem key='active' value='Active'>Active</MenuItem>
                                    <MenuItem key='expired' value='Expired'>Expired</MenuItem>
                                </Select>
                            </FormField>
                        </Grid>
                    </Grid>
                    
                    <MemberTable members={filteredMembers} />
                    {/* <MemberDataTable/> */}
                    {showMemberForm &&
                        <MemberForm member={undefined} editable={true} onClose={() => setShowMemberForm(false)} onSubmit={handleSubmit} />
                    }
                </>
            }
        </div>
    )
}

export default Members;