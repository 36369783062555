import {
    PayloadAction,
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import * as messageService from '../../service/message.service';

import { showNotification } from '../alerts/alert.slice';
import { NotificationType } from '../../shared/enums';
import { getAxiosErrorMessage } from '../../service/axios/axios.call.service';
import { PaginatedResponse } from '../../service/model/paginated-response';
import {
    getDefaultMemberMessageSearchCriteria,
    MemberMessageSearchCriteria,
    MemberMessage,
    MemberMessageRequest,
    MemberMessageSummary,
} from '../../service/model/message.model';
import { StatusResponseWithMessage } from '../../service/model/auth.model';

export interface MessageState {
    isLoading: boolean;
    summary?: MemberMessageSummary;
    page?: PaginatedResponse<MemberMessage>;
    totalPages: number;
    currentPage: number;
    totalRecords: number;
}

const memberMessageAdapter = createEntityAdapter({
    selectId: (message: MemberMessage) => message.id,
    sortComparer: (a, b) => b.createdOn?.localeCompare(a.createdOn),
});

export const initialState = memberMessageAdapter.getInitialState({
    isLoading: false,
    totalPages: 0,
    currentPage: 0,
    totalRecords: 0,
} as MessageState);

export const fetchMemberMessageSummary = createAsyncThunk<MemberMessageSummary>(
    'memberMessage/summary',
    async (_, { rejectWithValue }) => {
        return messageService.getMemberMessageSummary().catch((errors) => {
            return rejectWithValue(errors);
        });
    }
);

export const fetchMemberMessagePage = createAsyncThunk<
    PaginatedResponse<MemberMessage>,
    MemberMessageSearchCriteria
>('memberMessage/page', async (searchCriteria, { rejectWithValue }) => {
    return messageService
        .getMemberMessagePage(searchCriteria)
        .catch((errors) => {
            return rejectWithValue(errors);
        });
});

export const sendMessageToMember = createAsyncThunk<
    StatusResponseWithMessage,
    MemberMessageRequest
>('memberMessage/create', async (request, { rejectWithValue, dispatch }) => {
    return messageService
        .sendMessageToMember(request)
        .then((response) => {
            dispatch(
                showNotification({
                    message: response.message,
                    type:
                        response.status === 'success'
                            ? NotificationType.Success
                            : NotificationType.Error,
                })
            );
            setTimeout(() => {
                dispatch(
                    fetchMemberMessagePage({
                        ...getDefaultMemberMessageSearchCriteria(),
                        memberId: request.memberId,
                    })
                );
            }, 3000);
            // dispatch(fetchMembers());
            return response;
        })
        .catch((errors) => {
            dispatch(
                showNotification({
                    message: getAxiosErrorMessage(errors.response),
                    type: NotificationType.Error,
                })
            );
            return rejectWithValue(errors);
        });
});

const memberMessageSlice = createSlice({
    name: 'memberMessages',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMemberMessageSummary.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(
                fetchMemberMessageSummary.fulfilled,
                (state, action: PayloadAction<MemberMessageSummary>) => {
                    state.summary = action.payload;
                    state.isLoading = false;
                }
            )
            .addCase(fetchMemberMessageSummary.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(fetchMemberMessagePage.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(
                fetchMemberMessagePage.fulfilled,
                (
                    state,
                    action: PayloadAction<PaginatedResponse<MemberMessage>>
                ) => {
                    memberMessageAdapter.setAll(state, action.payload.data);
                    state.currentPage = action.payload.currentPage;
                    state.totalPages = action.payload.totalPages;
                    state.totalRecords = action.payload.totalRecords;
                    state.isLoading = false;
                }
            )
            .addCase(fetchMemberMessagePage.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(sendMessageToMember.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(
                sendMessageToMember.fulfilled,
                (state, action: PayloadAction<StatusResponseWithMessage>) => {
                    // messageAdapter.addOne(state, action.payload);
                    state.isLoading = false;
                }
            )
            .addCase(sendMessageToMember.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

export const { selectAll: selectMessages, selectById: selectMessageById } =
    memberMessageAdapter.getSelectors<RootState>(
        (state) => state.memberMessages
    );

export const {} = memberMessageSlice.actions;

export const selectIsLoading = (state: RootState) =>
    state.memberMessages.isLoading;
export const selectMemberMessageSummary = (state: RootState) =>
    state.memberMessages.summary;
export const selectCurrentPage = (state: RootState) =>
    state.memberMessages.currentPage;
export const selectTotalPages = (state: RootState) =>
    state.memberMessages.totalPages;
export const selectTotalRecords = (state: RootState) =>
    state.memberMessages.totalRecords;

export default memberMessageSlice.reducer;
