import React, {PropsWithChildren} from "react";
import { Card, CardContent, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Theme, Typography } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { createStyles, makeStyles } from "@mui/styles";

const navBarHeight = 64;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
            root: {
                display: 'flex',
                minHeight: `calc(100vh - ${navBarHeight}px)`,
                margin: 'auto',
                textAlign: 'center',
                // backgroundImage: `url(${mainBackground})`,
            },
            banner: {
                backgroundColor: `${theme.palette.success.main}`,
                height: '100px',
                fontSize: '24px',
                color: `${theme.palette.secondary.light}`,
                fontWeight: 'bold'
            },
            cardWrapper: {
                padding: '1rem',
                cursor: 'auto',
                [theme.breakpoints.up('md')]: {
                    height: '100%',
                }
            },
            card: {
                height: '400px',
                border: `${theme.palette.success.main} solid 1px`,
                '& .MuiCardActionArea-root': {
                    '&:hover': {
                        transform: "scale3d(1.05, 1.05, 1)"
                    }
                }
            },
            cardImage: {
                width: '40%',
                margin: 'auto'
            },
            price: {
                color: `${theme.palette.success.main}`
            },
            featureList: {
                color: 'grey',
                '& .MuiListItem-root': {
                    paddingTop: 0,
                    paddingBottom: 0
                }
            }

        }
    ))

interface PackageCardProps {
    readonly title: string
    readonly description: string
    readonly amount: string
    readonly unit: string
    // readonly icon: string
}

function PackageCard({title, children, description, amount, unit}: PropsWithChildren<PackageCardProps>) {
    const classes = useStyles();

    return <Grid item md={3} sm={12} xs={12} className={classes.cardWrapper}>
        <Card className={classes.card} elevation={2}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    {title}
                </Typography>
                <Typography variant="h3" className={classes.price} component="p">
                    {amount}
                </Typography>
                <Typography variant="h6" className={classes.price} component="p">
                    {unit}
                </Typography>
                <Divider style={{ marginBottom: "20px" }} />
                {children}
            </CardContent>
        </Card>
    </Grid>
}

export default function Pricing() {
    const classes = useStyles();

    return <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.root}>
        <Grid item lg={12} md={12} sm={12} xs={12} >
            <div className="text-center">
                <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.banner}>
                    Select a plan to suit your fitness center
                </Grid>
                <Grid container direction="row" justifyContent="center" className={classes.root}>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <PackageCard title="Starter"
                            description="For starter fitness centers"
                            amount="0"
                            unit="LKR/Month">
                            <List className={classes.featureList}>
                                <ListItem>
                                    <ListItemIcon><CheckCircleOutlineIcon color="success" /></ListItemIcon>
                                    <ListItemText primary="10 Members" sx={{color: "black"}} />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><CheckCircleOutlineIcon color="success" /></ListItemIcon>
                                    <ListItemText primary="2 Accounts" sx={{color: "black"}} />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><CheckCircleOutlineIcon color="success" /></ListItemIcon>
                                    <ListItemText primary="20 Free Messages" sx={{color: "black"}} />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><CheckCircleOutlineIcon color="success" /></ListItemIcon>
                                    <ListItemText primary="Attendance Tracking" sx={{color: "black"}} />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><CheckCircleOutlineIcon color="success" /></ListItemIcon>
                                    <ListItemText primary="Mobile App" sx={{color: "black"}} />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><CheckCircleOutlineIcon color="success" /></ListItemIcon>
                                    <ListItemText primary="Automated Payment Reminders" sx={{color: "black"}} />
                                </ListItem>
                            </List>
                        </PackageCard>
                        <PackageCard title="Basic"
                            description="For small-scale fitness centers"
                            amount="30"
                            unit="LKR/Member/Month">
                            <List className={classes.featureList}>
                                <ListItem>
                                    <ListItemIcon><CheckCircleOutlineIcon color="success" /></ListItemIcon>
                                    <ListItemText primary="200 Members" sx={{color: "black"}} />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><CheckCircleOutlineIcon color="success" /></ListItemIcon>
                                    <ListItemText primary="5 Accounts" sx={{color: "black"}} />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><CheckCircleOutlineIcon color="success" /></ListItemIcon>
                                    <ListItemText primary="200 Free Messages" sx={{color: "black"}} />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><CheckCircleOutlineIcon color="success" /></ListItemIcon>
                                    <ListItemText primary="Attendance Tracking" sx={{color: "black"}} />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><CheckCircleOutlineIcon color="success" /></ListItemIcon>
                                    <ListItemText primary="Mobile App" sx={{color: "black"}} />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><CheckCircleOutlineIcon color="success" /></ListItemIcon>
                                    <ListItemText primary="Automated Payment Reminders" sx={{color: "black"}} />
                                </ListItem>
                            </List>
                        </PackageCard>
                        <PackageCard title="Standard"
                            description="For medium-scale fitness centers"
                            amount="50"
                            unit="LKR/Member/Month">
                            <List className={classes.featureList}>
                                <ListItem>
                                    <ListItemIcon><CheckCircleOutlineIcon color="success" /></ListItemIcon>
                                    <ListItemText primary="200 Members" sx={{color: "black"}} />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><CheckCircleOutlineIcon color="success" /></ListItemIcon>
                                    <ListItemText primary="10 Accounts" sx={{color: "black"}} />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><CheckCircleOutlineIcon color="success" /></ListItemIcon>
                                    <ListItemText primary="400 Free Messages" sx={{color: "black"}} />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><CheckCircleOutlineIcon color="success" /></ListItemIcon>
                                    <ListItemText primary="Attendance Tracking" sx={{color: "black"}} />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><CheckCircleOutlineIcon color="success" /></ListItemIcon>
                                    <ListItemText primary="Mobile App" sx={{color: "black"}} />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><CheckCircleOutlineIcon color="success" /></ListItemIcon>
                                    <ListItemText primary="Automated Payment Reminders" sx={{color: "black"}} />
                                </ListItem>
                            </List>
                        </PackageCard>
                    </Grid>
                </Grid>
            </div>
            <div className="text-center" style={{ marginTop: '2rem' }}>
            </div>
        </Grid>
    </Grid>
}