import axios from "axios";
import { configs } from "../app/app-configs";
import { RegistrationRequest } from "./model/registration-request.model";

export const getOtp = async (email: string): Promise<string> => {
    return await axios.post(`${configs.BASE_URL}/registration/get-otp`, email, {
        headers: {
            'Content-Type': 'text/plain'
        }
    })
    .then(res => {
        return res.data as string;
    });
}

export const verifyOtp = async (request: RegistrationRequest, otp: string): Promise<string> => {
    const payload = {
        ...request,
        otp: otp,
    };
    return await axios.post(`${configs.BASE_URL}/registration/verify-otp`, payload)
    .then(res => {
        return res.data as string;
    });
}