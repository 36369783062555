import { Box, Button } from "@mui/material";
// import MembershipTable from "../features/memberships/components/membership-table";
import { makeStyles } from "@mui/styles";
// import MembershipForm from "../features/memberships/components/membership-form";
import { useState } from "react";
import { Membership } from "../service/model/membership.model";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import * as membershipSlice from "../features/memberships/membership.slice";
import Loader from "../components/loader";
import useMemberships from "../hooks/use-memberships";
import MembershipTable from "../features/memberships/components/membership-table";
import MembershipForm from "../features/memberships/components/membership-form";
import { selectLoggedInUser } from "../features/auth/auth.slice";

const useStyles = makeStyles({
    createButton: {
        float: "inline-end",
        marginBottom: '1rem'
    }
})

const Memberships = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const loggedInUserDetails = useAppSelector(selectLoggedInUser);
    const loggedInUser = loggedInUserDetails?.user;
    const [showMembershipForm, setShowMembershipForm] = useState(false);
    const isLoading = useAppSelector(membershipSlice.selectIsLoading);

    const [memberships] = useMemberships();

    const handleSubmit = (data: Membership) => {
        loggedInUser && dispatch(membershipSlice.createMembership({ ...data, businessId: loggedInUser.businessId })).then(resp => {
            if (resp.meta.requestStatus === 'fulfilled') {
                setShowMembershipForm(false);
            }
        });
    };

    return (
        <div>
            {isLoading &&
                <Loader />
            }
            {memberships &&
                <>
                    <Box sx={{ mb: "12px" }}>
                        <Button variant="contained" className={classes.createButton} onClick={() => setShowMembershipForm(true)} style={{ marginBottom: "12px" }}>Add Membership</Button>
                    </Box>
                    <MembershipTable memberships={memberships} />
                    {showMembershipForm &&
                        <MembershipForm membership={undefined} editable={true} onClose={() => setShowMembershipForm(false)} onSubmit={handleSubmit} />
                    }
                </>
            }
        </div>
    )
}

export default Memberships;