import * as React from 'react';
import { createStyles, makeStyles } from "@mui/styles";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { configs } from '../app/app-configs';
import ScrollToTop from './scroll-to-top';
import { ListItemText, MenuItem, Theme } from '@mui/material';
import logo from "../resources/images/logo.png";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 240;
// const navItems = ['Home', 'About', 'Contact', 'Login'];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
      menu: {
          padding: 0,
          '& .MuiMenuItem-root': {
            paddingTop: "12px",
            paddingBottom: "12px",
            // backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
          },
          '& .MuiListItemIcon-root': {
            marginRight: "20px",
          },
          '& .Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            '& .MuiSvgIcon-root': {
              color: theme.palette.primary.contrastText,
            },
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            }
          },
      }
  }
  ))

export default function Nav(props: Props) {
  const navigate = useNavigate();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const classes = useStyles();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2, color: "#641197" }}>
        {configs.APP_NAME}
      </Typography>
      <Divider />
      <div className='mobile-nav-link'><NavLink to="/" className={({isActive}) => isActive ? "active-mobile-nav-link" : "inactive-mobile-nav-link"}>HOME</NavLink></div>
      <div className='mobile-nav-link'><NavLink to="/pricing" className={({isActive}) => isActive ? "active-mobile-nav-link" : "inactive-mobile-nav-link"}>PRICING</NavLink></div>
      <div className='mobile-nav-link'><NavLink to="/features" className={({isActive}) => isActive ? "active-mobile-nav-link" : "inactive-mobile-nav-link"}>FEATURES</NavLink></div>
      <div className='mobile-nav-link'><NavLink to="/login" className={({isActive}) => isActive ? "active-mobile-nav-link" : "inactive-mobile-nav-link"}>LOGIN</NavLink></div>
      {/* <List className={classes.menu}>
        <MenuItem sx={{ cursor: 'pointer' }} onClick={() => onSelectItem("/")}>
          <ListItemText primary={"Home"}></ListItemText>
        </MenuItem>
        <MenuItem sx={{ cursor: 'pointer' }} onClick={() => onSelectItem("/pricing")}>
          <ListItemText primary={"Pricing"} />
        </MenuItem>
        <MenuItem sx={{ cursor: 'pointer' }} onClick={() => onSelectItem("/features")}>
          <ListItemText primary={"Features"} />
        </MenuItem>
        <MenuItem sx={{ cursor: 'pointer' }} onClick={() => onSelectItem("/login")}>
          <ListItemText primary={"Login"} />
        </MenuItem>
      </List> */}
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav" sx={{ backgroundColor: "white" }} elevation={0}>
        <Toolbar>
          <IconButton
            color="default"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <img src={logo} style={{ maxWidth: "24px" }} alt="" />
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, cursor: "pointer", color: "#641197" }}
            onClick={() => navigate('/')}
          >
            yminify
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <NavLink to="/" className={({isActive}) => isActive ? "active-nav-link" : "inactive-nav-link"}>HOME</NavLink>
            <NavLink to="/pricing" className={({isActive}) => isActive ? "active-nav-link" : "inactive-nav-link"}>PRICING</NavLink>
            <NavLink to="/features" className={({isActive}) => isActive ? "active-nav-link" : "inactive-nav-link"}>FEATURES</NavLink>
            <NavLink to="/login" className={({isActive}) => isActive ? "active-nav-link" : "inactive-nav-link"}>LOGIN</NavLink>
            {/* <Button sx={{ color: '#000'}} onClick={() => navigate('/')}>Home</Button>
            <Button sx={{ color: '#000'}} onClick={() => navigate('/pricing')}>Pricing</Button>
            <Button sx={{ color: '#000'}} onClick={() => navigate('/features')}>Features</Button>
            <Button sx={{ color: '#000'}} onClick={() => navigate('/login')}>Login</Button> */}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main" sx={{ width: '100%' }}>
        <Toolbar />
        <ScrollToTop />
        <Outlet />
      </Box>
    </Box>
  );
}