import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
    fetchMemberMessageSummary,
    selectMemberMessageSummary,
} from '../features/message/member-message.slice';

const useMemberMessageSummary = () => {
    const dispatch = useAppDispatch();
    const summary = useAppSelector(selectMemberMessageSummary);

    const getMemberMessageSummary = async () => {
        await dispatch(fetchMemberMessageSummary());
    };

    useEffect(() => {
        getMemberMessageSummary();
    }, []);

    return summary;
};

export default useMemberMessageSummary;
