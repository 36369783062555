import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
    fetchBusinessMessageSummary,
    selectBusinessMessageSummary,
} from '../features/message/business-message.slice';

const useBusinessMessageSummary = () => {
    const dispatch = useAppDispatch();
    const summary = useAppSelector(selectBusinessMessageSummary);

    const getBusinessMessageSummary = async () => {
        await dispatch(fetchBusinessMessageSummary());
    };

    useEffect(() => {
        getBusinessMessageSummary();
    }, []);

    return summary;
};

export default useBusinessMessageSummary;
