import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
    fetchBusinessMessagePage,
    selectMessages,
} from '../features/message/business-message.slice';
import { getDefaultBusinessMessageSearchCriteria } from '../service/model/message.model';

const useBusinessMessages = () => {
    const dispatch = useAppDispatch();
    const messages = useAppSelector(selectMessages);

    const getBusinessMessagePage = async () => {
        await dispatch(
            fetchBusinessMessagePage(getDefaultBusinessMessageSearchCriteria())
        );
    };

    useEffect(() => {
        if (messages.length === 0) {
            getBusinessMessagePage();
        }
    }, []);

    return [messages];
};

export default useBusinessMessages;
