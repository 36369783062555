import { useAppSelector } from "../../../../app/hooks";
import * as businessMessageSlice from "../../business-message.slice";
import useBusinessMessages from "../../../../hooks/use-business-messages";
import Loader from "../../../../components/loader";
import BusinessMessageTable from "./business-message-table";
import BusinessMessageSummary from "./business-message-summary";

const BusinessMessages = () => {
    const isLoading = useAppSelector(businessMessageSlice.selectIsLoading);

    const [businessMessages] = useBusinessMessages();

    return (
        <div>
            {isLoading &&
                <Loader />
            }
            {businessMessages &&
                <>
                    <BusinessMessageSummary />
                    <BusinessMessageTable messages={businessMessages} />
                </>
            }
        </div>
    )
}

export default BusinessMessages;