import { UseFormRegisterReturn, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { User } from "../../../service/model/user.model";
import { Box, Button, Dialog, DialogTitle, Grid, IconButton } from "@mui/material";
import FormField from "../../../components/form-controls/form-field";
import CloseIcon from '@mui/icons-material/Close';
import theme from "../../../app/app-theme";
import { TextInput } from "../../../components/form-input-field-wrappers/text-input";
import { DropdownInput } from "../../../components/form-input-field-wrappers/dropdown-input";
import { UserRoles } from "../../../shared/enums";
import { useAppSelector } from "../../../app/hooks";  
import { selectLoggedInUser } from "../../auth/auth.slice";
import useRoles from "../../../hooks/use-roles";
import { isAdmin, isOwner } from "../../../shared/utils";

interface UserFormProps {
    readonly user?: User
    readonly editable: boolean
    readonly onClose: () => void
    readonly onSubmit: (data: User) => void
}

const style = {
    // position: 'absolute' as 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    // width: '100%',
    // bgcolor: 'background.paper',
    // border: '2px solid #000',
    // boxShadow: 24,
    p: 2,
};

const validationSchema = yup.object().shape({
    username: yup.string().required("Username is required"),
    roles: yup.string().required("Role is required"),
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    email: yup.string().email("Not a valid email"),
    // gender: yup.string().required("Gender is required"),
    dateOfBirth: yup.string(),
    contact: yup.string(),
    address: yup.string(),
});

export type FormValues = yup.InferType<typeof validationSchema>;

const UserForm: React.FC<UserFormProps> = ({ user, editable, onClose, onSubmit }) => {
    const loggedInUser = useAppSelector(selectLoggedInUser);
    const [roles] = useRoles();
    const { register, handleSubmit, formState: { errors } } = useForm<FormValues>({
        resolver: yupResolver(validationSchema)
    });

    const onSubmitHandler = (data: any) => {
        const selectedRoles = [roles.find(role => role.name === data.roles)];
        data = {...data, roles: selectedRoles};
        
        const updatedUser = {
            ...user,
            ...data
        }
        onSubmit(updatedUser as User)
    };

    const getRegister = (name: keyof FormValues, validation?: any): UseFormRegisterReturn => {
        return register(name, validation);
    };

    const getRoleValue = (role: string) => {
        switch (role) {
            // case UserRoles.Admin:
            //     return "Admin";
            case UserRoles.Owner:
                return "Owner";
            case UserRoles.Staff:
                return "Staff";
            default:
                return "Invalid role";
        }
    };

    return (
        <Dialog open={true} onClose={onClose} sx={style} fullWidth maxWidth='md'>
            <DialogTitle sx={{ paddingBottom: 0 }}>User Details</DialogTitle>

            <Box sx={style}>
                <>
                    <IconButton aria-label="close" onClick={onClose} sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}>
                        <CloseIcon />
                    </IconButton>
                    <form onSubmit={handleSubmit(onSubmitHandler)}>
                        <Grid container direction="row" className="mt-0 my-0" justifyContent='space-between'>
                            <FormField>
                                <TextInput
                                    id="username"
                                    label="Username"
                                    defaultValue={user?.username}
                                    error={errors.username !== undefined}
                                    helperText={errors.username?.message}
                                    register={getRegister('username')}
                                />
                            </FormField>
                            {(isAdmin(loggedInUser?.user.roles ?? []) || isOwner(loggedInUser?.user.roles ?? [])) &&
                                <FormField>
                                    <DropdownInput
                                        id={"roles"}
                                        label="Role"
                                        defaultValue={user?.roles.at(0)?.name}
                                        error={errors.roles !== undefined}
                                        helperText={errors.roles?.message}
                                        options={roles.map(item => { return { label: getRoleValue(item.name), value: item.name } })}
                                        register={register("roles")}
                                    />
                                </FormField>
                            }
                            <FormField>
                                <TextInput
                                    id="firstName"
                                    label="First Name"
                                    defaultValue={user?.firstName}
                                    error={errors.firstName !== undefined}
                                    helperText={errors.firstName?.message}
                                    register={getRegister('firstName')}
                                />
                            </FormField>
                            <FormField>
                                <TextInput
                                    id="lastName"
                                    label="Last Name"
                                    defaultValue={user?.lastName}
                                    error={errors.lastName !== undefined}
                                    helperText={errors.lastName?.message}
                                    register={getRegister('lastName')}
                                />
                            </FormField>
                            <FormField>
                                <TextInput
                                    id="email"
                                    label="Email"
                                    defaultValue={user?.email}
                                    error={errors.email !== undefined}
                                    helperText={errors.email?.message}
                                    register={register("email")}
                                />
                            </FormField>
                            {/* <FormField>
                                <DropdownInput
                                    id={"gender"}
                                    label="Gender"
                                    defaultValue={user?.gender}
                                    error={errors.gender !== undefined}
                                    helperText={errors.gender?.message}
                                    options={["Male", "Female"].map(item => { return { label: item, value: item } })}
                                    register={register("gender")}
                                />
                            </FormField> */}
                            <FormField>
                                <TextInput
                                    id="dateOfBirth"
                                    label="Birth Day"
                                    type="date"
                                    defaultValue={user?.dateOfBirth ?? ''}
                                    error={errors.dateOfBirth !== undefined}
                                    helperText={errors.dateOfBirth?.message}
                                    register={register("dateOfBirth")}
                                />
                            </FormField>
                            <FormField>
                                <TextInput
                                    id="contact"
                                    label="Contact"
                                    defaultValue={user?.contact}
                                    register={register("contact")}
                                />
                            </FormField>
                            <FormField>
                                <TextInput
                                    id="address"
                                    label="Address"
                                    defaultValue={user?.address}
                                    register={register("address")}
                                />
                            </FormField>
                        </Grid>
                        <Grid container direction="row" justifyContent='end' sx={{ mt: 1 }}>
                            <Button type="button" variant="contained" sx={{ mr: 1, bgcolor: `${theme.palette.secondary.main}` }} onClick={onClose}>Cancel</Button>
                            <Button type="submit" variant="contained">Submit</Button>
                        </Grid>
                    </form>
                </>
            </Box>
        </Dialog>
    );
}

export default UserForm;