export enum UserRoles {
    Admin = 'ROLE_ADMIN',
    Owner = 'ROLE_OWNER',
    Staff = 'ROLE_STAFF'
}

export enum NotificationType {
    Success = 'success',
    Error = 'error',
    Warning = 'warning',
    Info = 'info',
}

export enum StatCardIcon {
    USER = 'USER',
    CALENDAR = 'CALENDAR',
    HOUR_GLASS = 'HOUR_GLASS',
    DUMBBELL = 'DUMBBELL',
    STORE = 'STORE',
    MEMBERSHIP = 'MEMBERSHIP',
    DOLLER = 'DOLLER',
    ATTEND = 'ATTEND',
}

export enum MessageType {
    PAYMENT_REMINDER = 'Payment Reminder',
    DIET_PLAN = 'Diet Plan',
    WORKOUT_SCHEDULE = 'Workout Schedule'
}

export enum MessageStatus {
    QUEUED = 'queued',
    SENT = 'sent',
    DELIVERED = 'delivered',
    READ = 'read',
    FAILED = 'failed',
    UNDELIVERED = 'undelivered'
}

export enum MessageChannel {
    WHATSAPP = 'whatsapp',
    SMS = 'sms'
}
