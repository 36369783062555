import { Role } from '../service/model/role.model';
import { UserRoles } from './enums';

export const isAdmin = (roles: Role[]): boolean => {
    return roles.some((role) => role.name === UserRoles.Admin);
};

export const isOwner = (roles: Role[]): boolean => {
    return roles.some((role) => role.name === UserRoles.Owner);
};

export const isStaffMember = (roles: Role[]): boolean => {
    return roles.some((role) => role.name === UserRoles.Staff);
};
