import axios from 'axios';
import { configs } from '../app/app-configs';
import { PaginatedResponse } from './model/paginated-response';
import {
    MemberMessageSearchCriteria,
    MemberMessage,
    MemberMessageRequest,
    MemberMessageSummary,
    BusinessMessageRequest,
    BusinessMessage,
    BusinessMessageSearchCriteria,
    BusinessMessageSummary,
} from './model/message.model';
import { StatusResponseWithMessage } from './model/auth.model';

export const getMemberMessagePage = async (
    search: MemberMessageSearchCriteria
): Promise<PaginatedResponse<MemberMessage>> => {
    if (search.memberId) {
        return await axios
            .get(
                `${configs.BASE_URL}/message/member/${search.memberId}?page=${search.pagination.page}&size=${search.pagination.size}&from=${search.from}&to=${search.to}`
            )
            .then((res) => {
                return res.data as PaginatedResponse<MemberMessage>;
            });
    } else {
        return await axios
            .get(
                `${configs.BASE_URL}/message/member?page=${search.pagination.page}&size=${search.pagination.size}&from=${search.from}&to=${search.to}`
            )
            .then((res) => {
                return res.data as PaginatedResponse<MemberMessage>;
            });
    }
};

export const getBusinessMessagePage = async (
    search: BusinessMessageSearchCriteria
): Promise<PaginatedResponse<BusinessMessage>> => {
    if (search.businessId) {
        return await axios
            .get(
                `${configs.BASE_URL}/message/business/${search.businessId}?page=${search.pagination.page}&size=${search.pagination.size}&from=${search.from}&to=${search.to}`
            )
            .then((res) => {
                return res.data as PaginatedResponse<BusinessMessage>;
            });
    } else {
        return await axios
            .get(
                `${configs.BASE_URL}/message/business?page=${search.pagination.page}&size=${search.pagination.size}&from=${search.from}&to=${search.to}`
            )
            .then((res) => {
                return res.data as PaginatedResponse<BusinessMessage>;
            });
    }
};

export const sendMessageToMember = async (
    request: MemberMessageRequest
): Promise<StatusResponseWithMessage> => {
    return await axios
        .post(`${configs.BASE_URL}/message/member`, request)
        .then((res) => {
            return res.data as StatusResponseWithMessage;
        });
};

export const sendMessageToBusiness = async (
    request: BusinessMessageRequest
): Promise<StatusResponseWithMessage> => {
    return await axios
        .post(
            `${configs.BASE_URL}/message/business`,
            request
        )
        .then((res) => {
            return res.data as StatusResponseWithMessage;
        });
};

export const getMemberMessageSummary =
    async (): Promise<MemberMessageSummary> => {
        return await axios
            .get(`${configs.BASE_URL}/message/member/summary`)
            .then((res) => {
                return res.data as MemberMessageSummary;
            });
};

export const getBusinessMessageSummary =
    async (): Promise<BusinessMessageSummary> => {
        return await axios
            .get(`${configs.BASE_URL}/message/business/summary`)
            .then((res) => {
                return res.data as BusinessMessageSummary;
            });
};
