import axios from "axios";
import { configs } from "../app/app-configs";
import { Role } from "./model/role.model";

export const getRoles = async (): Promise<Role[]> => {
    return await axios.get(`${configs.BASE_URL}/role`)
    .then(res => {
        return res.data as Role[];
    });
}
