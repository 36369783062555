import axios from "axios";
import { configs } from "../app/app-configs";
import { Attendance, AttendanceSearchCriteria, AttendanceSummary, QrAttendanceRequest } from "./model/attendance.model";
import { PaginatedResponse } from "./model/paginated-response";
import { StatusResponseWithMessage } from "./model/auth.model";

// export const getAttendances = async (): Promise<Attendance[]> => {
//     return await axios.get(`${configs.BASE_URL}/attendance`)
//     .then(res => {
//         return res.data as Attendance[];
//     });
// }

export const getAttendancePage = async (
    search: AttendanceSearchCriteria
): Promise<PaginatedResponse<Attendance>> => {
    return await axios
        .get(
            `${configs.BASE_URL}/attendance?page=${search.pagination.page}&size=${search.pagination.size}&from=${search.from}&to=${search.to}&name=${search.name}`
        )
        .then((res) => {
            return res.data as PaginatedResponse<Attendance>;
        });
};

export const createAttendance = async (request: Attendance): Promise<Attendance> => {
    return await axios.post(`${configs.BASE_URL}/attendance`, request)
    .then(res => {
        return res.data as Attendance;
    });
}

export const createAttendanceQR = async (request: QrAttendanceRequest): Promise<StatusResponseWithMessage> => {
    return await axios.post(`${configs.BASE_URL}/attendance/qr`, request)
    .then(res => {
        return res.data as StatusResponseWithMessage;
    });
}

export const updateAttendance = async (request: Attendance): Promise<Attendance> => {
    return await axios.put(`${configs.BASE_URL}/attendance/${request.id}`, request)
    .then(res => {
        return res.data as Attendance;
    });
}

export const deleteAttendance = async (id: number): Promise<number> => {
    return await axios.delete(`${configs.BASE_URL}/attendance/${id}`)
    .then(res => {
        return res.data;
    });
}

export const getAttendanceSummary = async (): Promise<AttendanceSummary> => {
    return await axios.get(`${configs.BASE_URL}/attendance/summary`)
    .then(res => {
        return res.data as AttendanceSummary;
    });
}
