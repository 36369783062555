import { useAppSelector } from "../../../../app/hooks";
import * as memberMessageSlice from "../../member-message.slice";
import useMemberMessages from "../../../../hooks/use-member-messages";
import Loader from "../../../../components/loader";
import MemberMessageTable from "./member-message-table";
import MemberMessageSummary from "./member-message-summary";

const MemberMessages = () => {
    const isLoading = useAppSelector(memberMessageSlice.selectIsLoading);

    const [memberMessages] = useMemberMessages();

    return (
        <div>
            {isLoading &&
                <Loader />
            }
            {memberMessages &&
                <>
                    <MemberMessageSummary />
                    <MemberMessageTable messages={memberMessages} />
                </>
            }
        </div>
    )
}

export default MemberMessages;