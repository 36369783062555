import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Dialog, DialogTitle, Grid, IconButton, TextField, Typography } from '@mui/material';
import { useForm, UseFormRegisterReturn } from 'react-hook-form';
import * as yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import FormField from '../form-controls/form-field';
import { TextInput } from '../form-input-field-wrappers/text-input';
import React, { useState } from 'react';
import theme from '../../app/app-theme';
import { RegistrationRequest } from '../../service/model/registration-request.model';
import { getOtp, verifyOtp } from '../../service/registration.service';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useAppDispatch } from '../../app/hooks';
import { showNotification } from '../../features/alerts/alert.slice';
import { NotificationType } from '../../shared/enums';

interface RegistrationFormProps {
    readonly onClose: () => void
}

const style = {
    p: 2,
};

const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    address: yup.string(),
    email: yup.string().required("Email is required").email("Not a valid email"),
    contact: yup.string().required("Contact is required"),
});

export type FormValues = yup.InferType<typeof validationSchema>;

const RegistrationForm: React.FC<RegistrationFormProps> = ({ onClose }) => {
    const dispatch = useAppDispatch();
    const [userDetails, setUserDetails] = useState<RegistrationRequest>();
    const [isOtp, setIsOtp] = useState(false);
    const [otp, setOtp] = useState('');
    const [isRegistrationCompleted, setIsRegistrationCompleted] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm<FormValues>({
        resolver: yupResolver(validationSchema)
    });

    const onSubmitHandler = async (data: RegistrationRequest) => {
        setUserDetails(data);
        await getOtp(data.email).then(res => {
            if (res) {
                setIsOtp(true);
            }
        }).catch(e => {
            dispatch(
                showNotification({
                    message: e.response.data,
                    type: NotificationType.Error,
                })
            );
        })
    };

    const handleOtpSubmit = async () => {
        if (userDetails) {
            await verifyOtp(userDetails, otp).then(res => {
                if (res) {
                    setIsRegistrationCompleted(true);
                }
            }).catch(e => {
                dispatch(
                    showNotification({
                        message: e.response.data,
                        type: NotificationType.Error,
                    })
                );
            })
        }
    };

    const getRegister = (name: keyof FormValues, validation?: any): UseFormRegisterReturn => {
        return register(name, validation);
    };

    return (
        <>
            {!isOtp && !isRegistrationCompleted &&
                <Dialog open={true} onClose={onClose} sx={style} fullWidth maxWidth='md'>
                    <DialogTitle sx={{ paddingBottom: 0 }}>Registration Form</DialogTitle>
                    <Box sx={style}>
                        <>
                            <IconButton aria-label="close" onClick={onClose} sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}>
                                <CloseIcon />
                            </IconButton>
                            <form onSubmit={handleSubmit(onSubmitHandler)}>
                                <Grid container direction="row" className="mt-0 my-0" justifyContent='space-between'>
                                    <FormField>
                                        <TextInput
                                            id="name"
                                            label="Name"
                                            error={errors.name !== undefined}
                                            helperText={errors.name?.message}
                                            register={getRegister('name')}
                                        />
                                    </FormField>
                                    <FormField>
                                        <TextInput
                                            id="address"
                                            label="Address"
                                            register={register("address")}
                                        />
                                    </FormField>
                                    <FormField>
                                        <TextInput
                                            id="contact"
                                            label="Contact Number"
                                            error={errors.contact !== undefined}
                                            helperText={errors.contact?.message}
                                            register={register("contact")}
                                        />
                                    </FormField>
                                    <FormField>
                                        <TextInput
                                            id="email"
                                            label="Email"
                                            error={errors.email !== undefined}
                                            helperText={errors.email?.message}
                                            register={register("email")}
                                        />
                                    </FormField>
                                </Grid>
                                <Grid container direction="row" justifyContent='end' sx={{ mt: 1 }}>
                                    <Button type="button" variant="contained" sx={{ mr: 1, bgcolor: `${theme.palette.secondary.main}` }} onClick={onClose}>Cancel</Button>
                                    <Button type="submit" variant="contained">Get OTP</Button>
                                </Grid>
                            </form>
                        </>
                    </Box>
                </Dialog>
            }

            {isOtp && !isRegistrationCompleted &&
                <Dialog open={true} onClose={onClose} sx={style} fullWidth maxWidth='xs'>
                    <DialogTitle sx={{ paddingBottom: 0 }}>Enter OTP here</DialogTitle>
                    <Box sx={style}>
                        <>
                            <IconButton aria-label="close" onClick={onClose} sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}>
                                <CloseIcon />
                            </IconButton>
                            <Grid container direction="row" className="mt-0 my-0">
                                <TextField
                                    id="otp"
                                    label="OTP"
                                    fullWidth
                                    value={otp}
                                    onChange={e => setOtp(e.currentTarget.value)}
                                />
                            </Grid>
                            <Grid container direction="row" justifyContent='end' sx={{ mt: 1 }}>
                                <Button type="button" variant="contained" sx={{ mr: 1, bgcolor: `${theme.palette.secondary.main}` }} onClick={() => setIsOtp(false)}>Cancel</Button>
                                <Button type="submit" variant="contained" disabled={otp.length !== 6} onClick={handleOtpSubmit}> Submit </Button>
                            </Grid>
                        </>
                    </Box>
                </Dialog>
            }

            {isRegistrationCompleted &&
                <Dialog open={true} onClose={onClose} sx={style} fullWidth maxWidth='md'>
                    <DialogTitle sx={{ paddingBottom: 0 }}>
                        Registration Completed
                    </DialogTitle>
                    <Box sx={{ p: 3, justifyItems: 'center' }}>
                        <>
                            <IconButton aria-label="close" onClick={onClose} sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}>
                                <CloseIcon />
                            </IconButton>

                            <CheckCircleOutlineIcon color="success" height={20} />
                            <Typography>
                                Your registration has been completed successfully. One of our agents will be in touch with you shortly.
                            </Typography>
                        </>
                    </Box>
                </Dialog>
            }
        </>
    );
}

export default RegistrationForm;