import { useEffect, useState } from "react";
import StyledTable, { ColumnHeader } from "../../../../components/table/styled-table";
import BusinessMessageTableRow from "./business-message-table-row";
import { configs } from "../../../../app/app-configs";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import DateRangeInput from "../../../../components/form-input-field-wrappers/date-range-input";
import Grid from "@mui/material/Grid";
import { getDefaultBusinessMessageSearchCriteria, BusinessMessage } from "../../../../service/model/message.model";
import * as messageSlice from "../../business-message.slice";

const headers: ColumnHeader[] = [
    { name: "Id" },
    { name: "Business" },
    { name: "Message" },
    { name: "Timestamp" },
    { name: "Status" },
]

interface TableProps {
    readonly businessId?: number
    readonly messages: BusinessMessage[]
}

const BusinessMessageTable: React.FC<TableProps> = ({ businessId, messages }) => {
    const dispatch = useAppDispatch();
    const totalPages = useAppSelector(messageSlice.selectTotalPages);
    const [page, setPage] = useState(1);

    const [dateRange, setDateRange] = useState({
        startDate: new Date(getDefaultBusinessMessageSearchCriteria().from),
        endDate: new Date(getDefaultBusinessMessageSearchCriteria().to),
    });

    useEffect(() => {
        dispatch(messageSlice.fetchBusinessMessagePage({
            pagination: { page: page - 1, size: configs.PAGE_SIZE },
            businessId: businessId,
            from: dateRange.startDate.toISOString().slice(0, 10),
            to: dateRange.endDate.toISOString().slice(0, 10)
        }))
    }, [page, dateRange]);

    const handlePageChange = (selectedPage: number) => {
        setPage(selectedPage);
    }

    const handleDateChange = (start: Date | null, end: Date | null) => {
        setDateRange({ startDate: start ?? new Date(), endDate: end ?? new Date() });
    };

    return (
        <>
            <Grid container direction="row" style={{ marginTop: '12px' }}>
                <DateRangeInput
                    initialStartDate={dateRange.startDate}
                    initialEndDate={dateRange.endDate}
                    onDateChange={handleDateChange}
                />
            </Grid>
            <StyledTable headers={headers}
                currentPage={page}
                totalPages={totalPages}
                onPageChange={handlePageChange}>

                {messages?.map(message =>
                    <BusinessMessageTableRow message={message} key={message.id} />
                )}
            </StyledTable>
        </>
    );
}

export default BusinessMessageTable;